






import Vue from 'vue';
import Scaffold from '@/components/Scaffold.vue';
import HeroHeader from '@/components/HeroHeader.vue';
import TourList from '@/components/TourList.vue';
import { mapGetters } from 'vuex';
export default Vue.extend({
  components: { Scaffold, HeroHeader, TourList },
  data() {
    return {
      showAccountMenu: false,
      buttons: [
        // {
        //   label: 'Neue Tour',
        //   onClick: () => {
        //     this.$router.push({ name: 'New Tour' });
        //   },
        // },
      ],
    };
  },
  mounted() {
    this.$store.dispatch('tour/bindToursRef');
    if (window.screen.width < 480) {
      this.$router.push({
        name: 'Mobile Discover',
      });
    } else {
      window.Intercom('update', {
        /* eslint @typescript-eslint/camelcase: 0 */
        hide_default_launcher: false,
      });
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/getUser',
      tours: 'tour/getTours',
    }),
  },
  methods: {},
});
